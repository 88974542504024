svg.logomark , svg.loading-logo {
  height: auto;
  position: absolute;
  height: auto;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  transition: 0.85s  $ease-out-quart;
  margin: auto;
}

svg.logomark {
  width: 60em;
  margin-left: -12.5em;
  @include media-breakpoint-up(sm) { 
    width: 70em;
    margin-left: -15em;
  }
  &.full {
    margin-left: 0;
    width: 18em;
    @include media-breakpoint-up(md) { 
      width: 30em;
    }
  }
  #logomark {
    opacity: 0;
    #vertical-line {
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
    }
    #line1 {
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
    }
    #line2{
      stroke-dasharray: 120;
      stroke-dashoffset: 120;
    }
    #line3 {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
    }
    #line4 {
      stroke-dasharray: 120;
      stroke-dashoffset: 120;
    }
    #line5 {
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
    }
    #line6 {
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
    }
  }
  &.animate {
    #logomark {
      opacity: 1;
      transition: 0.1s  $ease-out-quart;
      #vertical-line {
        animation: dash 3.5s linear forwards;
      }
      #line1 {
        animation: dash 0.9s linear forwards;
      }
      #line2 {
        animation-delay: 0.1s;
        animation: dash 1.92s linear forwards;
      }
      #line3 {
        animation-delay: 0.2s;
        animation: dash 1.4s linear forwards;
      }
      #line4 {
        animation-delay: 0.3s;
        animation: dash 1.6s linear forwards;
      }
      #line5 {
        animation-delay: 0.4s;
        animation: dash 0.6s linear forwards;
      }
      #line6 {
        animation-delay: 0.45s;
        animation: dash 0.8s linear forwards;
      }
    }
  }
}

$class: letter-;

svg.loading-logo {
  width: 18em;
  @include media-breakpoint-up(md) { 
    width: 30em;
  }
  #letter {
    path {
      opacity: 0;
    }
  }

  #tagline {
    opacity: 0;
  }
  
  &.animate {
    #letter path {
      animation: fade-up 0.75s forwards;

      @for $i from 1 through 8 {
        &.#{$class}#{$i}{
          animation-delay: 0.6s + ($i / 11);
        }
      }
    }

    #tagline {
      animation: fade-down 0.85s forwards;
      animation-delay: 1.5s;
      transform: translateY(0.8em);
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade-up {
  from {
    transform: translateY(0.8em);

  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
