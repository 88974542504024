#homepage {
  @include media-breakpoint-down(md) {
    padding: 38px 0px !important;
    overflow: hidden;
  }
  .img-right {
    margin-top: -2.15em;
  }

  .container-pattern {
    top: 0;

    @include media-breakpoint-down(sm) {
      width: 200px;
    }

    img {
      max-width: 100%;
      max-height: 50vh;
      top: 0;
      transform: translateY(-50%);
    }
  }

  .container-pattern + p:first-of-type {
    padding-top: 30vh;
  }

  .row--body {
    min-height: 100vh;
    height: auto;
  }

  .row--body p {
    width: 80%;
  }

  .img-right {
    background: url('../img/entrance.jpg') no-repeat;
    background-position: -2em -5em;
    height: 475px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-size: cover;
    @include media-breakpoint-up(md) { 
      background-position: -2em -4em;
      width: 100%;
      height: 111vh;
      top: 0;
      left: 0%;
      margin-top: -4em;
      // background-size: calc(100% + 6em);
    }
  }

  p {
    font-size: 15px;

    @include media-breakpoint-down(md) {
      width: 80%;
      margin: 0rem 7rem 1rem !important;
    }
  }

  .bg-box-right {
    background-color: $orange;
    position: absolute;
    max-height: 22em;
    bottom: 2em;
    width: 70%;
    left: -3em;
    @include media-breakpoint-up(md) { 
      height: 22em;
      bottom: 8em;
      left: 0;
    }
  }

  .container-pattern {
    @include media-breakpoint-down(md) {
      margin-left: auto;
      width: 15rem;
    }
  }
  
  .img-col-first {
    width: 22rem;
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .main-content {
    @include media-breakpoint-up(md) {
      height: 50vh;
    }
    @include media-breakpoint-down(md) {
      top: 15%;
      left: 28%;
      padding: 0;
    }
    h1 {
      @include media-breakpoint-down(md) {
        font-size: 50px;
        padding-left: 1rem;
      }
    }
  }

  .tilt__title {
    min-width: 5em;
    -webkit-text-stroke: 1px $body-bg;
    text-stroke: 1px $body-bg;
    position: absolute;

    .tilt__title-inner {
      -webkit-text-stroke: 1px $body-bg;
    }
  }

}