.tilt__title {
	// position: relative;
	margin: 0;
	color: $beige;
	-webkit-text-stroke: 1px $beige;
    text-stroke: 1px $beige;
    // grid-column-end: span 2;
    pointer-events: none;
    opacity: 1;
}

@media only screen and (min-device-width : 768px) { 
    .tilt__title {
        opacity: 1 !important;
    }
}

.tilt__title-inner:not(:last-child) {
	-webkit-text-stroke: 1px $beige;
    text-stroke: 1px $beige;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
	color: transparent;
	opacity: 0.7;
}

.tilt__title-inner {
	position: absolute;
	will-change: transform;
}