body {
  background-color: $body-bg;
  font-family: 'Gotham Book';
  font-weight: normal;
  color: $body-text-color;
  font-size: 0.85em;
  @include media-breakpoint-up(md) { 
    font-size: 0.85em;
    text-align: left;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @include media-breakpoint-up(xl) { 
    font-size: 0.95em;
  }
}

a {
  color: $link-text-color;
  &:hover {
    color: $link-text-hover;
  }
}

$font-title: 'Bodoni SvtyTwo';
$font-description: "FuturaStd Medium";

h1,
h2,
h3,
h4,
h5 {
  color: $title-color;
  font-family: $font-title;
}

h1 {
  font-size: 3em;
  font-weight: 800;
  @include media-breakpoint-up(sm) { 
    font-size: 4em;
  }
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 0.9em;
}

.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}