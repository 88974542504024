
.sub-menu {
  width: 100%;
  height: 100%;
  opacity: 1;
  padding: 0 0 0 2%;
  top: 0;
  position: fixed;
  margin: 0;
  background-color: $beige;
  border-radius: 0;
  z-index: 11;
  transform: translateX(-100%);
  transition: all 0.8s $ease-out-quart;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: darken($beige, 10%);
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.7s $ease-out-quart;
  }
  
  ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  li {
      display: block;
      list-style: none;
      height: auto;
      margin: 0 0 1rem;

      a {
        width: 100%;
        font-size: 16px;
        font-weight: normal !important;
        text-transform: uppercase;
        color: #fff;
        display: block;
        line-height: 30px;
        padding: 0 1rem;
        position: relative;
      }
      a:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scaleX(0);
        transition: all 0.3s $ease-out-expo;
        will-change: transform;
      }

      // animate
      a:hover {
        font-weight: bold !important;
        text-decoration: none;

        &:after {
          transform: scaleX(1);
        }
      }
  }
  
}

.sub-menu.oppenned {
  transform: translateX(0);
  transition-delay: 0.2s;
  
  &::before {
    transform: translateX(100%);
  }
}


img.forefront-logo {
  width: 200px;
  position: absolute;
  top: 4%;
  left: 3%;

  @include media-breakpoint-down(md) { 
    width: 5rem;
  }
}
 
img.fourbyfore-logo {
  width: 120px;
  position: absolute;
  top: 4%;
  right: 3%;
  
  @include media-breakpoint-down(md) { 
    width: 3rem;
  }
}

.container-menu {
  position: relative;
  margin:auto;
  width: 70%;
  height: 100%;
  border-top-left-radius: 80vw;
  border-top-right-radius: 80vw;
  background-color: $dusty-red;
  padding-left: 0;
  margin-top: 2%;
  margin-bottom: 0;

  &:active {
    animation-duration: 3s;
    animation-delay: 2s;
  }

   img.logo {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 5em;

      @include media-breakpoint-up(md) {
        width: 7em;
      }
   }

   .btn {
    color: $beige;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%,-50%);
   }
}

.container-menu.animate {
  // transform: scale(1);
}

// hamburguer menu • http://callmenick.com/_development/css-hamburger-menu-icons/

.c-hamburger {
  width: 44px;
  height: 42px;
  font-size: 0;
  text-indent: -9999px;
  display: block;
  position: fixed;
  left: 2%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity:1;
  overflow: hidden;
  margin: 10px;
  padding: 0;
  background: transparent;
  box-shadow: none;
  border: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s $ease-out-quart;
  z-index: 10;

  @include media-breakpoint-down(md) {
    margin-left: 2.5rem;
  }

  &:focus {
    outline: none;
  }

  div {
    padding: 0px 20px;
    width: 43px;
    height: 42px;
    border-radius: 25px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    height: 2px;
    width: 20px;
    transition: all 0.3s ease;

    &:before, &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      content: "";
    }

    &:before {
      top: -7px;
    }
    
    &:after {
      bottom: -7px;
    }
  }
}

.c-hamburger:hover {
  transform: translate(-50%, -50%) scale(1.2);
  
  span {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.c-hamburger--htx {
  span {
    -webkit-transition: background transform 0.3s ease-out;
    transition: background transform 0.3s ease-out;
    &:before, &:after {
      -webkit-transition-duration: 0.3s, 0.3s ease-out;
      transition-duration: 0.3s, 0.3s ease-out;
      -webkit-transition-delay: 0.3s, 0s ease-out;
      transition-delay: 0.3s, 0s ease-out;    
    }
    &:before {
        -webkit-transition-property: top, -webkit-transform;
        transition-property: top, transform;
      }
    &:after {
      -webkit-transition-property: bottom, -webkit-transform;
      transition-property: bottom, transform;
    }
  }
  // &.is-active {
  //   span {
  //     background: none;   

  //     &:before, &:after {
  //       -webkit-transition-delay: 0s, 0.3s ease-out;
  //       transition-delay: 0s, 0.3s ease-out;
  //     } 
    
  //     &:before {
  //       top: 0;
  //       -webkit-transform: rotate(45deg);
  //       -ms-transform: rotate(45deg);
  //       transform: rotate(45deg);
  //     }
  //     &:after {
  //       bottom: 0;
  //       -webkit-transform: rotate(-45deg);
  //       -ms-transform: rotate(-45deg);
  //       transform: rotate(-45deg);
  //     }
  //   }
  // }
} 

.button {
  width: 150px;
}

.button-close {
  width: 160px;

  @include media-breakpoint-up(md) {
    width: 180px;
  }
}