@font-face {
  font-family: 'Bodoni SvtyTwo';
  src: url('../fonts/Bodoni-SvtyTwo-ITC-TT-Bold.woff2') format('woff2'),
      url('../fonts/Bodoni-SvtyTwo-ITC-TT-Bold.woff') format('woff'),
      url('../fonts/Bodoni-SvtyTwo-ITC-TT-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff'),
      url('../fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaStd Light';
  src: url('../fonts/FuturaStd-Light.woff2') format('woff2'),
      url('../fonts/FuturaStd-Light.woff') format('woff'),
      url('../fonts/FuturaStd-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaStd Medium';
  src: url('../fonts/FuturaStd-Medium.woff2') format('woff2'),
      url('../fonts/FuturaStd-Medium.woff') format('woff'),
      url('../fonts/FuturaStd-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}