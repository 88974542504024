#dining {
  background-color: $dusty-red !important;
  min-height: 100vh;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  transition: 0.2s $ease-out-quart;

  @include media-breakpoint-up(md) { 
    max-width: 1920px;
  }

  .row {
    & > div {
      position: relative;
    }
  }

  &.show {
    opacity: 1;
  }

  .content-cover {
    background-color: $dusty-red !important;
  }

  .main-content {
    padding: 1em 10vw 2.5em 25vw;
  
    position: absolute !important;
    top: 0%;
    left: 10%;
    transform: translateX(-40%);

    @include media-breakpoint-up(md) { 
      margin-left: -2em;
      padding: 5em 0 7em 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5em 0 7em calc(3em + 1%);
  
      border-left: 0px solid $border-color; 
    }

    @include media-breakpoint-up(xl) { 
      padding: 16vh 4em 7em calc(5em + 1%);
    }

    @include media-breakpoint-up(xxl) { 
      padding: 16vh calc(4em + 3%) 7em calc(5em + 2%);
      height: 50vh;
    }
  }

  .page-title h1 {
    color: $beige;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $beige;
    }
  }

  .img-right {
    background: url('../img/main-dining.jpg') no-repeat;
    background-size: cover;
    @include media-breakpoint-up(sm) { 
      background-position: -2em -15em;
      margin-top: -2em;
    }
    @include media-breakpoint-up(md) { 
    }
  }

  .img-left {
    background: url('../img/dining-left.jpg') no-repeat;
    background-size: cover;
    width: 100%;
    height: 150px;
    background-position: center;
    position: relative;
    z-index: 2;
    
    @include media-breakpoint-up(sm) { 
      height: 22em;
      position: absolute;
    }
  }

  .bg-box-right {
    width: 70%;
    max-height: 10em;
    background-color: $beige;
    position: relative;
    right: 0;
    bottom: 0;
    z-index: 1;

    @include media-breakpoint-up(sm) { 
      height: 22em;
      position: absolute;
    }
  }

  img.img-col-first{
    width: 80%;
    position: relative;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: -15px;
    
    @include media-breakpoint-up(md) { 
      width: 330px;
      margin-top: 0;
      margin-right: 0;
      position: absolute;
      bottom: 4rem;
      right: 0;
    }
  }

  .container-pattern {
    position: relative;
    text-align: right;
    max-height: 25vh;

    img {
      max-width: 100%;
      width: auto;
      max-height: 50vh;
      top: 0;
      transform: translateY(-50%);
    }
  }

  .container-pattern + p:first-of-type {
    margin-top: 1rem !important;
  }
  
  p {
    width: 100%;
    color: $beige;
    font-family: $font-description;
    font-size: 15px;
    padding-left: 6rem;

    @include media-breakpoint-up(md) {
      padding-left: 6rem;
      width: 100%;
    }
  }
}

.container-img-events {
  position: relative;
  
  @include media-breakpoint-up(md) { 
    height: 400px;
    margin-top: -8rem;
  }
}

.logo-container {
  background-color: $beige;
  position: relative;

  .logo-therefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.5em;
    height: auto;
    margin: 2rem 2rem;
    @include media-breakpoint-up(md) { 
      width: 8em;
    }
    @include media-breakpoint-up(lg) {  
      width: 12em;
    }
  }
}

.row--body {
  height: auto;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }
}

.btn-book {
  position: relative;
  display: block;
  padding-left: 6rem;
  
  @include media-breakpoint-up(md) { 
    display: inline-block;
    padding-left: 0;
    position: absolute;
    left: 45%;
    transform: translateX(-40%);
    bottom: 10%;
  }
}
 

/*--------------
    Animate
---------------*/

.content-cover {
  background-color: $beige !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: width 0.6s  $ease-in-out;
  display: none;
  z-index: 1;
  @include media-breakpoint-up(md) { 
    display: block; // COMMENT OUT FOR TEST
  }
  &.slide-right {
    right: 0;
  }
  &.slide-left {
    left: 0;
  }
}

.logo-content {
  margin-top: 1.5em;
  @include media-breakpoint-up(md) { 
    max-width: 24%;
    flex: 0 0 24%;
    margin-top: -3em;
  }
  .logo-img {
    width: 3.5em;
    height: auto;
    margin-left: 16vw;
    @include media-breakpoint-up(sm) { 
      width: 5em;
    }
    @include media-breakpoint-up(md) { 
      width: 4.2em;
      margin-left: 8vw;
    }
    @include media-breakpoint-up(lg) {  
      width: 5em;
      margin-left: 9vw;
    }
    @include media-breakpoint-up(xxl) {  
      margin-left: 10em;
    }
  }
}

.bg-box-left {
  background-color: $landing-box-left-bg;
  height: 50vh;
  position: absolute;
  width: 15vw;
  left: 0;
  top: 8em;
  margin-left: 0;
  z-index: 0;
  @include media-breakpoint-up(sm) { 
    width: 12vw;
  }
  @include media-breakpoint-up(md) { 
    top: 17vh;
    width: 6em;
    max-height: 22em;
    z-index: 2;
  }
  @include media-breakpoint-up(xxl) { 
    top: 22vh;
    height: 40vh;
    max-height: 40em;
  }
}

/*--------------------
     General Title
---------------------*/

.main-content {
  padding: 1em 10vw 2.5em 25vw;
  z-index: 5;

  position: absolute !important;
  left: 40%;
  transform: translateX(-40%);
  bottom: -8%;
  @include media-breakpoint-up(md) { 
    margin-left: -2em;
    padding: 5em 0 7em 0;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    padding: 5em 0 7em calc(3em + 1%);

    border-left: 0px solid $border-color; 
  }
  @include media-breakpoint-up(xl) { 
    padding: 16vh 4em 7em calc(5em + 1%);
  }
  @include media-breakpoint-up(xxl) { 
    padding: 16vh calc(4em + 3%) 7em calc(5em + 2%);
    height: 50vh;
    // min-height: 20em;
  }
  
.page-title {
  position: relative;
  display: block;
    h1 {
      color: $body-bg;
      letter-spacing: 1px;
      font-size: 6em;
      @include media-breakpoint-up(md) { 
        opacity: 0;
      }
    }
    > span {
      @extend h1;
      min-width: 5em;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transition: 0.3s $linear;
      display: none; // COMMENT OUT FOR TEST
      @include media-breakpoint-up(md) { 
        display: block;
      }

      &.title-stroke, &.title-stroke-2 {
        // -webkit-text-stroke: 1px $body-text-color;
        -webkit-text-stroke: 1px $body-bg;
        -webkit-text-fill-color: transparent;
        opacity: 0.6;
      }
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) { 
      h1 {
        opacity: 1;
      }
      span {
        display: none;
      }
    }
  }
}
