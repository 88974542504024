
#contact-us.container--body {
  background-color: $green !important;
  min-height: 100vh;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  transition: 0.2s $ease-out-quart;

  @include media-breakpoint-up(md) { 
    // padding: 5vh 0 0 0;
    max-width: 1920px;
  }

  .row {
    & > div {
      position: relative;
    }
  }

  &.show {
    opacity: 1;
  }

  .row--body {
    height: auto;
  }

  .content-cover {
    background-color: $green !important;
  }

  .container-pattern {
    width: 100%;
    position: absolute !important;
    left: 0;
    bottom: 0;
    
    @include media-breakpoint-up(md) {
      width: auto;
      left: 4rem;
    }

    img {
      transform: scaleY(-1);
      
      @include media-breakpoint-up(md) {
        width: 100%;
        top: 0;
        transform: none;
      }
    }
  }

  .container-description {
    margin: 2rem 0;
    
    p {
      width: 100%;
      color: $beige;
      font-family: $font-description;
      font-size: 15px;
      padding-left: 1rem;
      
      @include media-breakpoint-up(md) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .main-content {
    padding: 5rem 0 3rem;
    
    position: absolute !important;
    top: 0%;
    left: 0;
    bottom: 0;

    @include media-breakpoint-up(md) { 
      margin-left: -2rem;
      padding: 5rem 0 7rem 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5rem 0 7rem 4rem;
  
      border-left: 0px solid $border-color; 
    }

    @include media-breakpoint-up(xl) { 
      padding: 8vh 4em 7em 4rem;
    }
  }

  .page-title h1 {
    color: $beige;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $beige;
    }
  }

  .tilt__title {
    min-width: 5em;
    position: absolute;

    @include media-breakpoint-down(sm) {
      font-size: 4em;
    }
  }

  .img-right {
    background: url('../img/well-tempered-salmon.jpg') no-repeat;
    background-position: -2em -5em;
    height: 260px;
    width: 85%;
    position: relative;
    left: 2em;
    margin-top: -1em;
    z-index: 1;
    background-size: cover;
    @include media-breakpoint-up(sm) { 
      background-position: -2em -15em;
      margin-top: -2em;
      height: 28em;
    }
    @include media-breakpoint-up(md) { 
      background-position: -2em -4em;
      width: 70%;
      height: 55vh;
      top: 0;
      left: 20.5%;
      margin-top: -4em;
      position: absolute;
      background-size: calc(100% + 4em);
    }
  }
  
  .bg-box-right {
    background-color: $beige;
    width: 60%;
    height: 15em;
    position: absolute;
    top: 5em;
    right: 0;
    max-height: 22em;
    @include media-breakpoint-up(sm) { 
      height: 22em;
      height: 52vh;
      top: 0vh;
    }
    @include media-breakpoint-up(md) { 
      max-height: 40em;
    }
  }
  .container-foods-link li a {
    color: $beige;
  }
  
  .tab-content p {
    color: black;
  }

  .description-left {
    background: transparent;
    position: absolute;
    top: 10em;
    right: -10em;
    z-index: 5;
    width: auto;
    background-position: 10px;
    padding: 2rem 2rem 5rem;
  }

  .container-img-right {
    margin: 25vh 0 0 0;
  }
}


/*--------------------
        Form
---------------------*/


#form {
  background-color: transparent;
  // min-height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  input, select, textarea {
    background-color: transparent;
    color: $beige !important;
    font-family: $font-description;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid $beige;
    box-shadow: none;
  }
}

form {
  width: 100%;
  max-width: 900px;
}

label {
  color: $beige;
  font-family: $font-description;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.form-control {
  width: 100% !important;
}

.form-check-label {
  text-transform: none;
}

.container-btn-submit {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  button {
    &:focus, &:active, &:hover {
      text-decoration: none;
      outline: none;
    }
  }
}


select {
  text-align: left;
  color: #98D4BF;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;

  outline: 0;
  border: 0;
  border-bottom: 2px solid #98D4BF;
  border-radius: 0;

  appearance: none;
  background-image: url("../img/arrow.png") !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 20px;
  max-width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  @include media-breakpoint-up(md) {
    max-width: 80% !important;
  }
  option {
    color: $green;
  }
}

textarea {
  border: 1px solid $beige !important;
}

.select option:hover,
.select option:focus {
  background-color: $green !important;
  border: 1px solid $beige;
}


