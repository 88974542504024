
#visit-us.container--body {
  background-color: $beige !important;
  min-height: 100vh;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  transition: 0.2s $ease-out-quart;
  @include media-breakpoint-up(md) { 
    // padding: 5vh 0 0 0;
    max-width: 1920px;
  }
  .row {
    & > div {
      position: relative;
    }
  }
  &.show {
    opacity: 1;
  }

  .row--body {
    height: auto;

    @include media-breakpoint-up(md) {
      height: 100vh;
    }
  }

  .main-content {
    padding: 1em 10vw 2.5em 25vw;
    position: relative;
    @include media-breakpoint-down(sm) {
      position: relative !important;
      padding: 0px;
    }
    @include media-breakpoint-up(md) { 
      position: absolute !important;
      top: 0%;
      left: 10%;
      transform: translateX(-40%);

      margin-left: -2em;
      padding: 5em 0 7em 0;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5em 0 7em calc(3em + 1%);
  
      border-left: 0px solid $border-color; 
    }
    @include media-breakpoint-up(xl) { 
      padding: 16vh 4em 7em calc(5em + 38%);
    }
    @include media-breakpoint-up(xxl) { 
      padding: 16vh calc(4em + 3%) 7em calc(5em + 38%);
      height: 50vh;
      // min-height: 20em;
    }
  }

  .visit-info {
    padding-top: 150px;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }    
  }

  .page-title h1 {
    color: $red;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $red;
    }
  }

  .tilt__title {
    min-width: 5em;
    color: $red;
    position: absolute;
    .tilt__title-inner {
      -webkit-text-stroke: 1px $red;
    }
  }

  p {
    color: $red;
    font-family: $font-description;
    font-family: 15px;
    margin: 0px 0px 10px 0px !important;
    width: 75%;
    span {
      font-size: 13px;
    }
    a {
      cursor: pointer;
      color: $red;
      font-family: $font-description;
    }
  }

  h3 {
    color: $red;
    font-family: $font-description;
    font-weight: 700;
    font-size: 20px;
  }

}

/*-------------------
      Location
---------------------*/

iframe,   
object,  
embed {
  border: 0;
  width: 100%;
  // height: auto;
  height: 15em;
  @include media-breakpoint-up (md) {
    height: 33em;
    width: 100%;
  }
}

.container-icon ul {
  list-style: none;
  padding-left: 0;
}

.container-icon li {
  margin-bottom: 10px;
}

/*-----------------------
  Location Description
--------------------------*/

.icon-img {
  // display: inline-block;
  float: left;
  width: 25px;
  padding-right: 10px;
}

.six {
  // font-family: Myriad Pro;
  margin: 1px;  
  font-size: 22px;
  // background-color:#540f45;
  padding: 5px 5px 3px 4px;
  margin:4px;
}

