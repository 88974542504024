
#drinks.container--body {
  background-color: $dusty-red !important;
  min-height: 100vh;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  transition: 0.2s $ease-out-quart;

  @include media-breakpoint-up(md) { 
    // padding: 5vh 0 0 0;
    max-width: 1920px;
  }

  .row {
    & > div {
      position: relative;
    }
  }

  &.show {
    opacity: 1;
  }

  .row--body {
    height: auto;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      height: 100vh;
    }
  }

  .content-cover {
    background-color: $dusty-red !important;
  }

  .container-pattern {
    position: absolute !important;
    top: -23rem !important;
    right: 0%;
    img {
      width: 100%;
    }
  }

  .main-content {
    padding: 1rem 10vw 2.5rem 25vw;
  
    position: absolute !important;
    top: 0%;
    left: 0;

    @include media-breakpoint-up(md) { 
      margin-left: -2rem;
      padding: 5rem 0 7rem 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5rem 0 7rem 6rem;
  
      border-left: 0px solid $border-color; 
    }

    @include media-breakpoint-up(xl) { 
      padding: 8vh 4rem 7rem 6rem;
    }
  }

  .page-title h1 {
    color: $beige;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $beige;
    }
  }

  .img-right {
    // background: url('../img/menu/cheesy-gratin-tude.jpg') no-repeat;
    background-size: cover !important;
    background-repeat:no-repeat !important;
    width: 100%;

    @include media-breakpoint-up(sm) { 
      background-position: -2em -15em;
      margin-top: -2em;
    }
    @include media-breakpoint-up(md) { 
      height: 70vh;
    }
  }
  
  .container-foods-link li a {
    color: $beige;
  }
  
  .tab-content p {
    color: black;
    font-size: 15px;
  }
  
  .foods-navbar button {
    color: $beige;
  }

  .description-left h2 {
    background: transparent;
    position: absolute;
    margin-left: -45%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: auto;
    background-position: 10px;
  }

  .container-foods-link {
    li a:hover, a.active, a:focus {
      background-color: $green !important;
      color: beige !important;
    }
  }

  .foodsmenu-button::after {
    background: $beige;
  }
  
  .menu-item {
    color: $beige;
    
    h2 {
      font-family: $font-title;
    }
    p {
      color: $beige;
      padding-bottom: 3px;
    }
  }

  .menu-img {
    margin: 0;
    overflow: hidden;
    padding-bottom: 2em;

    @include media-breakpoint-up(md) {
      margin-top: -2em;
      overflow: unset;
    }

    .img-right {
      background: url('../img/drinks/fruity-flamingo.jpg') no-repeat;
      margin-left: 0;
      margin-top: 0;
      width: 200px;
      height: 200px;
      top: 2em;
      position: relative;

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 10em;
      }

      @include media-breakpoint-up(lg) {
        width: 250px;
        height: 250px;
        top: 4em;
      }
      
      @include media-breakpoint-up(xxl) {
        width: 300px;
        height: 300px;
        top: 6em;
      }
    }
  }
}

.container-img-drink {
  // display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: example 0.8s forwards 0s;
}

@keyframes example {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.drinks-container {
  display: none;
  &.active{
    display: block;
  }
}

.box-left {
  position: absolute !important;
  bottom: -9%;
  left: 56%;
  width: 210px;
  height: 355px;
  background-color: $beige;
}

.drinks-top-image {
  display: flex;
  top: 0;
  padding-left: 50%;
  
  @include media-breakpoint-up(md) {
    position: absolute !important;
    left: 40%;
    transform: translateX(-5%);
    padding-left: 0;
  }

  .img-left {
    background: url('../img/drinks/rose-latte.jpg') no-repeat;
    background-size: cover;
    height: 150px;
    width: 300px;
    
    @include media-breakpoint-up(md) {
      height: 180px;
      width: 180px;
    }
    @include media-breakpoint-up(lg) {
      height: 200px;
      width: 200px;
    }
  }

  .bg-box-right {
    width: 100px;
    height: 110px;
    background: $beige;
    margin-top: -18%;
    border: none;
    
    @include media-breakpoint-up(md) {
      width: 230px;
      height: 140px;
      margin-top: -10%;
    }
    
    @include media-breakpoint-up(lg) {
      margin-top: -4%;
    }
    
    @include media-breakpoint-up(lg) {
      height: 160px;
    }
    
  }
}

.container-pattern-bottom {
  display: none;
  position: absolute;
  left: 10em;
  bottom: 12vh;
  z-index: 1;

  img {
    width: 60vw;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}