
#events {
  background-color: $dusty-red !important;;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  padding-top: 2rem;
  transition: 0.2s $ease-out-quart;
  
  @include media-breakpoint-up(md) { 
    max-width: 1920px;
    padding-top: 0;
  }
  .row {
    & > div {
      position: relative;
    }
  }
  &.show {
    opacity: 1;
  }

  .content-cover {
    background-color: $dusty-red !important;
  }

  .container-pattern {
    position: absolute !important;
    bottom: 0 !important;
    right: 5%;
    transform: translateX(0%);
    img {
      width: 80%;
    }
  }

  .row.center {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      height: 100vh;
    }
  }

  p {
    font-family: $font-description;
    font-size: 15px;
    color: $beige;
    padding-left: 6rem;
  }

  #form {
    width: 100%;
    padding-left: 5rem;

    @include media-breakpoint-up(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .form-control {
    width: 100%;
  }

  .main-content {
    // display: none;
    padding: 1em 10vw 2.5em 25vw;
    position: relative;
    @include media-breakpoint-down(sm) {
      position: relative !important;
      padding: 0px;
    }
    @include media-breakpoint-up(md) { 
      position: absolute !important;
      top: -15%;
      left: 10%;
      transform: translateX(-40%);

      margin-left: -2em;
      padding: 5em 0 7em 0;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5em 0 7em calc(3em + 1%);
  
      border-left: 0px solid $border-color; 
    }
    @include media-breakpoint-up(xl) { 
      padding: 16vh 4em 7em calc(5em + 38%);
    }
    @include media-breakpoint-up(xxl) { 
      padding: 16vh calc(4em + 3%) 7em calc(5em + 38%);
      height: 50vh;
    }
  }

  .page-title h1 {
    color: $beige;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $beige;
    }
  }

  
  .bg-box-right {
    background-color: $beige;
    width: 60%;
    height: 15em;
    position: absolute;
    bottom: 0em;
    right: 0;
    max-height: 22em;
    transform: translateY(0px) !important;
    @include media-breakpoint-up(sm) { 
      height: 23em;
    }
    @include media-breakpoint-up(md) { 
      max-height: 40em;
    }
  }
  
  .container-img-right {
    margin: 25vh 0 0 0;
  }
}
