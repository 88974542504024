
#foods.container--body {
  background-color: $beige;
  min-height: 100vh;
  max-width: 100%;
  opacity: 0; // COMMENT OUT FOR TEST
  transition: 0.2s $ease-out-quart;

  @include media-breakpoint-up(md) { 
    max-width: 1920px;
  }

  .row {
    & > div {
      position: relative;
    }
  }

  &.show {
    opacity: 1;
  }

  .row--body {
    height: auto;
  }

  .container-pattern {
    position: absolute !important;
    bottom: -30rem !important;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down (md) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .main-content {
    padding: 1em 10vw 2.5em 25vw;
  
    position: relative !important;
    top: 0%;
    left: 10%;
    transform: translateX(-40%);

    @include media-breakpoint-up(md) { 
      margin-left: 5em;
      padding: 5em 0 7em 0;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      padding: 5em 0 7em calc(12em + 1%);
  
      border-left: 0px solid $border-color; 
    }

    @include media-breakpoint-up(xl) { 
      padding: 8vh 4em 7em calc(12em + 1%);
    }

    @include media-breakpoint-up(xxl) { 
      padding: 8vh calc(4em + 3%) 7em calc(12em + 2%);
      height: 50vh;
    }
  }

  .page-title h1 {
    color: $grey-dark;
    &.title-stroke, &.title-stroke-2 {
      -webkit-text-stroke: 1px $grey-dark;
    }
  }

  .tilt__title {
    color: $grey-dark;
    .tilt__title-inner {
      -webkit-text-stroke: 1px $grey-dark;
    }
  }

  .img-right {
    // background: url('../img/menu/cheesy-gratin-tude.jpg') no-repeat;
    background-size: cover !important;
    background-repeat:no-repeat !important;
    background-size: calc(100% + 4em) !important;
    // background-size: auto !important;
    @include media-breakpoint-up(sm) { 
      // background-position: -2em -15em;
      margin-top: -2em;
    }
    @include media-breakpoint-up(md) { 
      height: 150px;
    }
    @include media-breakpoint-up(lg) { 
      height: 240px;
    }
    @include media-breakpoint-up(xl) { 
      height: 290px;
    }
    @include media-breakpoint-up(xxl) { 
      height: 360px;
    }
  }
  li a {
    color: $red;
  }

  .description-left {
    background: $green;
  }

  .container-foods-link {
    position: relative;
    // height: 100%;
    @include media-breakpoint-up(md) {
      position: absolute;
    }
  }

  .container-img-menu {
    @include media-breakpoint-up(md) {
      margin-top: 8rem;
    }
  }
  .container-foods-link {
    li a:hover, a.active, a:focus {
      background-color: $dusty-red !important;
      color: beige !important;
    }
  }

  .foodsmenu-button::after {
    background: $green;
  }
  
}


/*----------------------
    Foods & Drinks
----------------------*/

.description-left {
  position: relative;
  right: -20px;
  z-index: 5;
  // background: $green;
  width: 100%;
  background-position: 10px;
  padding: 2rem 2rem 5rem;
  // @include media-breakpoint-up(sm) { 
  //   height: 22em;
  //   height: 52vh;
  // }
  @include media-breakpoint-up(md) { 
    // max-height: 40em;
  }
  h2 {
    font-family: $font-title;
    color: $beige;
    font-size: 35px;
    letter-spacing: 1px;
    padding: 0px 15px;
  }
  p {
    color: $beige !important;
    margin-right: 40px;
    font-family: $font-description;
    font-size: 15px;
  }
  .logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 30px;
  }
}

.container-foods-link {
  height: 7rem;
  li {
    float: left; 
    padding: 5px; 
    text-decoration: none; 
    list-style-type: none;
    // width: 100%;
    @include media-breakpoint-up(md) {
      width: 33.33%;
    }
    // &:hover, &.active, &:focus {
    //   background-color: $dusty-red;
    //   color: beige !important;
    //   padding: 4px 8px;
    // }
  }
  li a {
    font-family: $font-description;
    text-decoration: none;
  }
  li a:hover, a.active, a:focus {
    padding: 4px 8px !important;
  }
}

.tab-content p {
  color: black;
  width: 75%;
  @include media-breakpoint-up(md) {
    padding-bottom: 5rem;
  }
}

.foods-navbar {
  border: 2px solid $green;
  position: absolute;
  right: 1em;
  z-index: 6;

  @include media-breakpoint-up(md) {
    top: 30vh;
    left: 25%;
    right: unset;
    border: none;
  }

  @include media-breakpoint-down(md) {
    width: 50%;
    background-color: $beige;
    padding: 1rem;
    position: fixed;
    right: 2rem;
  }
  
  button {
    display: block;
    background-color: transparent;
    border: none;
    font-family: $font-description;
    &:hover, &:focus, &.active {
      font-weight: 700 !important;
      text-decoration: none;
      outline: none;
    }
  }
}

.foods-wrapper {
  padding-top: 180px;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.foods-container {
  display: none;
  padding-bottom: 4rem;

  &.active{
    display: block;

    @include media-breakpoint-up(md) {
      display: grid;
      padding-top: 4rem;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 300px;
    }
  }
}

.container-img-menu {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  // animation: example 0.8s forwards 0s;
  @include media-breakpoint-up(md) {
    left: -4rem;
  }
}

@keyframes example {
  from {
    width: 0;
  }
  to {
    width: 120%;
  }
}

.img-mousemove {
  transform: matrix(1, 0, 0, 1, 15, 15);
}

.foodsmenu-button::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width .2s;
}

.foodsmenu-button:hover::after,
.foodsmenu-button.active::after {
  width: 100%;
  // transition: width .3s;
}

.menu-item {
  color: $red;
  margin-bottom: 1rem;

  h2 {
    font-family: $font-title;
  }

  p {
    width: auto;
    padding-top: 0;
    color: $red;
  }
}

.menu-img {
  margin-top: -2em;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-top: -15em;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -2em;
  }

  @include media-breakpoint-up(xxl) {
    margin-top: -4em;
  }
  
  @include media-breakpoint-down(md) {
    position: absolute;
    bottom: 80px;
  }

  .bg-box-left {
    position: relative;
    top: 0;
    left: 0;
    width: 200px;
    height: 17.5em;
    background: $green;
    
    @include media-breakpoint-up(md) {
      top: 1em;
      width: 15em;
      height: 12em;
      left: -22em;
    }
    @include media-breakpoint-up(lg) {
      left: -8em;
      width: 18em;
      height: 20em;
    }
    @include media-breakpoint-up(xl) {
      top: 1em;
      width: 23em;
      height: 30em;
    }
    @include media-breakpoint-up(xxl) {
      top: 3.8em;
      width: 25em;
      height: 32em;
    }
  }

  .img-right {
    background: url('../img/foods/sataylor-swift.jpg') no-repeat;
    margin-left: -2em;
    width: 197px;
    height: 200px;
    position: absolute;
    z-index: 2;
    bottom: 1em;
    
    @include media-breakpoint-up(md) {
      bottom: -2.5em;
      width: 130px;
      margin-left: -12em;
    }
    @include media-breakpoint-up(lg) {
      bottom: 0;
      width: 200px;
      margin-left: 4em;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 8em;
      bottom: -2em;
      width: 253px;
    }
    @include media-breakpoint-up(xxl) {
      width: 314px;
    }
  }
}

.list-menu {
  .top-pattern {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      position: relative;
      top: -6.5em;
      margin-left: 2em;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 3.5em;
    }
  }
}

.foods-starter,
.foods-brunch,
.foods-pasta,
.foods-mains,
.foods-fancy-greens,
.foods-sweet-treats {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 3rem;

  .list-menu {
    padding-right: 5rem;
  }
}

.foods-drinks {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;

  .list-menu {
    padding-right: 5rem;
  }
}