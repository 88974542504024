#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $body-bg;
  z-index: 99;
  transition: 0.6s $ease-out-quart;
  // display: none; // COMMENT OUT WHEN NOT TEST
  > img {
    position: absolute;
    max-width: 90%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.hide {
    height: 0;
    pointer-events: none;
  }
}

.logo-container {
  background-color: $beige;
  position: relative;
  .logo-therefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px !important;
    // height: auto;
    margin: 2rem 2rem;
    @include media-breakpoint-up(md) { 
      width: 8em;
    }
    @include media-breakpoint-up(lg) {  
      width: 12em;
    }
  }
}

.container--body {
  background-color: $beige;
  min-height: 100vh;
  max-width: 100%;
  width: 100% !important;
  // opacity: 0; // COMMENT OUT FOR TEST
  // transition: 0.2s $ease-out-quart;
  @include media-breakpoint-up(md) { 
    // padding: 5vh 0 0 0;
    max-width: 1920px;
  }
  // .row {
  //   & > div {
  //     position: relative;
  //   }
  // }
  // &.show {
  //   opacity: 1;
  // }
}

.container-pattern {
  position: relative;
  text-align: center;
  img {
    position: relative;
    top: -50px;
    width: 70%;
  }
}

.row--body {
  height: 100vh;
  img.img-col-first{
    width: 12rem;
    position: absolute;
    bottom: 0;
    display: block !important;
  }
  p {
    width: 65%;
    padding-top: 1rem;
    color: $orange;
    width: 115px;
    margin: auto !important;
    font-family: $font-description;
    font-size: 15px;
  }
}

/*--------------
    Animate
---------------*/

.content-cover {
  background-color: $beige !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: width 0.6s  $ease-in-out;
  display: none;
  z-index: 1;
  @include media-breakpoint-up(md) { 
    display: block; // COMMENT OUT FOR TEST
  }
  &.slide-right {
    right: 0;
  }
  &.slide-left {
    left: 0;
  }
}

.logo-content {
  margin-top: 1.5em;
  @include media-breakpoint-up(md) { 
    max-width: 24%;
    flex: 0 0 24%;
    margin-top: -3em;
  }
  .logo-img {
    width: 3.5em;
    height: auto;
    margin-left: 16vw;
    @include media-breakpoint-up(sm) { 
      width: 5em;
    }
    @include media-breakpoint-up(md) { 
      width: 4.2em;
      margin-left: 8vw;
    }
    @include media-breakpoint-up(lg) {  
      width: 5em;
      margin-left: 9vw;
    }
    @include media-breakpoint-up(xxl) {  
      margin-left: 10em;
    }
  }
}

.bg-box-left {
  background-color: $landing-box-left-bg;
  height: 50vh;
  position: absolute;
  width: 15vw;
  left: 0;
  top: 8em;
  margin-left: 0;
  z-index: 0;
  @include media-breakpoint-up(sm) { 
    width: 12vw;
  }
  @include media-breakpoint-up(md) { 
    top: 17vh;
    width: 6em;
    max-height: 22em;
    z-index: 2;
  }
  @include media-breakpoint-up(xxl) { 
    top: 22vh;
    height: 40vh;
    max-height: 40em;
  }
}


/*--------------------
        Title
---------------------*/

// .main-content {
//   padding: 1em 10vw 2.5em 25vw;
//   z-index: 1 !important;

//   position: absolute !important;
//   left: 40%;
//   transform: translateX(-40%);
//   bottom: -8%;
//   @include media-breakpoint-up(md) { 
//     margin-left: -2em;
//     padding: 5em 0 7em 0;
//   }
//   @include media-breakpoint-up(lg) {
//     margin-left: 0;
//     padding: 5em 0 7em calc(3em + 1%);

//     border-left: 0px solid $border-color; 
//   }
//   @include media-breakpoint-up(xl) { 
//     padding: 16vh 4em 7em calc(5em + 1%);
//   }
//   @include media-breakpoint-up(xxl) { 
//     padding: 16vh calc(4em + 3%) 7em calc(5em + 2%);
//     height: 50vh;
//   }
  
.page-title {
  position: relative;
  display: block;
  font-family: $font-title;
    h1 {
      color: $body-bg;
      letter-spacing: 1px;
      font-size: 6em;
      @include media-breakpoint-up(md) { 
        opacity: 0;
      }
    }
    > span {
      @extend h1;
      min-width: 5em;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transition: 0.3s $linear;
      display: none; // COMMENT OUT FOR TEST
      @include media-breakpoint-up(md) { 
        display: block;
      }

      &.title-stroke, &.title-stroke-2 {
        // -webkit-text-stroke: 1px $body-text-color;
        -webkit-text-stroke: 1px $body-bg;
        -webkit-text-fill-color: transparent;
        opacity: 0.6;
      }
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) { 
      h1 {
        opacity: 1;
      }
      span {
        display: none;
      }
    }
  }
// }

.img-left {
  background: url('../img/sink.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  @include media-breakpoint-down(md) { 
    background-position: 0px;
    height: 37vh;
  }
  @include media-breakpoint-up(md) { 
    background-position: 10px;
    height: 52vh;
  }
}

.img-right {
  background: url('../img/entrance.jpg') no-repeat;
  background-position: -2em -15em;
  background-size: cover;
  @include media-breakpoint-up(sm) { 
    background-position: -2em -15em;
    margin-top: -2em;
  }
  @include media-breakpoint-up(md) { 
    height: 107vh;
  }
}

// .bg-box-right {
//   background-color: $orange;
//   position: absolute;
//   max-height: 22em;
//   bottom: 8em;
//   width: 70%;
//   @include media-breakpoint-up(sm) { 
//     height: 22em;
//   }
//   @include media-breakpoint-up(md) { 
//   }
// }

ul.social-media {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: block;
    margin-right: 0.3em;
    margin-top: 0.5em;
    .svg {
      width: 1.3em;
      height: auto;
      fill: $green;
      &:hover {
        fill: $red;
      }
    }
  }
}

button .button {
  fill: $green !important;
  &:hover {
    fill: $red !important;
  }
}

.hover-beige:hover {
  fill: $dusty-red !important;
} 

.hover-red:hover {
  fill: $green !important;
}

// Change color

.theme-beige {
  background-color: $beige !important;
}

.hamburger-red {
  background: $dusty-red;
  &:before, &:after {
    background: $dusty-red;
  }
}

.theme-red {
  background-color: $dusty-red;
}

.hamburger-beige {
  background: $beige;
  &:before, &:after {
    background: $beige;
  }
}

.btn, button {
  &:hover, &.active, &:focus {
    text-decoration: none !important;
    outline: none !important;
  }
}


